import React from 'react'
import Viewer from 'react-viewer'

const Annexe = ({ annexes, index, open, close }) => {
	return (
		<Viewer
			visible={open}
			images={annexes}
			noImgDetails
			scalable={false}
			changeable={false}
			activeIndex={index}
			onClose={close}
			noNavbar={annexes.length < 2}
		/>
	)
}


export default Annexe
